<template>
  <v-navigation-drawer
    :rail="miniSidebar"
    class="v3-navigation"
    width="240"
    permanent
    disable-route-watcher
  >
    <div class="v3-navigation__top-container">
      <router-link
        v-if="!miniSidebar"
        to="/"
      >
        <img
          :src="supaduLogo"
          alt="Supadu"
          class="v3-navigation__logo"
        >
      </router-link>
      <v-btn
        color="white"
        variant="plain"
        size="medium"
        icon="menu"
        :ripple="false"
        @click="dispatchMiniSidebar(!miniSidebar)"
      />
    </div>
    <v-select
      v-if="accounts?.accounts.length > 1 && !miniSidebar"
      class="v3-navigation__accounts-select"
      :items="accounts.accounts"
      :model-value="accounts.account.name"
      label="Select Account"
      item-title="name"
      item-value="accountID"
      @update:model-value="changeAccount($event)"
    >
      <template #prepend-inner>
        <v-icon class="hidden-sm-and-down">
          book
        </v-icon>
      </template>
    </v-select>
    <v-list
      class="v3-navigation__list"
    >
      <v-list-item
        v-for="item in filteredNavItems"
        :key="item.name"
        class="v3-navigation__list--item"
        :to="{ name: item.name }"
      >
        <template #prepend>
          <v-icon :icon="item.icon" />
        </template>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <template #append>
      <div class="pa-2">
        <LogoutMenu />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import LogoutMenu from '@/components/navigation/LogoutMenu';
import { isZoneAuthorised } from '@/utils';
import { useDisplay } from 'vuetify';
import supaduLogo from '@/assets/supadu-logo-white.svg';

export default {
  name: 'MainSidebar',
  components: {
    LogoutMenu
  },
  setup() {
    const { smAndDown } = useDisplay();
    return { smAndDown, supaduLogo };
  },
  data() {
    return {
      navItems: [{
        name: 'Widgets',
        icon: 'widgets',
        path: '/widgets',
      }, {
        name: 'Metadata',
        icon: 'find_in_page',
        path: '/metadata',
      }, {
        name: 'Users',
        icon: 'people',
        path: '/users',
      }, {
        name: 'Sites',
        icon: 'web_asset',
        path: '/sites',
      }, {
        name: 'Account',
        icon: 'book',
        path: '/account',
      },
      {
        name: 'Transactions',
        icon: 'currency_exchange',
        path: '/transactions',
      }],
    };
  },
  computed: {
    ...mapState(['accounts', 'navigationDraw']),
    ...mapGetters(['superAdmin', 'isAuthenticated']),
    miniSidebar() {
      return this.smAndDown ? true : this.navigationDraw.miniSidebar;
    },
    filteredNavItems() {
      return this.navItems.filter((navItem) => isZoneAuthorised(navItem.path, this.$store));
    },
  },
  methods: {
    ...mapActions(['setAccount', 'dispatchMiniSidebar']),
    changeAccount(accountID) {
      const selected = this.accounts.accounts.find((account) => account.accountID === accountID);
      this.setAccount({ account: selected });
    },
  },
};
</script>

<style lang="scss">
.v3-navigation {
  background-size: 240px auto !important;
  background-repeat: no-repeat !important;
  background-position: bottom center !important;
  background: url('~@/assets/v3-nav-background.png'),
    linear-gradient(
      to right,
      rgb(var(--v-theme-primary)),
      rgb(var(--v-theme-accent))
    );
  &__top-container {
    display: flex;
    padding: 16px 16px 8px;
    justify-content: space-between;
  }
  &__logo {
    max-height: 60px;
  }

  &__accounts-select {
    color: white;
    padding: 16px;
  }

  &__list {
    background: transparent !important;
    padding: 0 !important;
    margin: 0;

    .v-list-item {
      &__action {
        min-width: auto;
        margin-right: 10px;
      }
    }

    &--item {
      font-size: 20px;
      border-left: 3px solid rgb(var(--v-theme-white));
      background: transparent !important;
      height: 48px !important;
      color: rgb(var(--v-theme-white)) !important;
      text-transform: uppercase;
      opacity: 1;
      transition: all 0.3s;

      .v-icon {
        color: rgb(var(--v-theme-white)) !important;
        font-size: 30px;
      }

      &:not(.v-list-item--active) {
        border-left: 3px solid transparent;
        background: transparent !important;
        opacity: 0.5;
        transition: all 0.3s;

        &:hover {
          border-left: 3px solid rgb(var(--v-theme-white));
          background: transparent !important;
          opacity: 1;
        }
      }
    }
  }
}
</style>
