import ls from 'localstorage-ttl';

const state = {
  websites: ls.get('websites') || [],
  pages: ls.get('pages') || [],
  selectedPage: ls.get('selectedPage') || {},
  previews: ls.get('previews') || [],
  website: ls.get('website') || null,
  allWebsites: ls.get('allWebsites') || [],
};

const getters = {
  siteID: (state) => state.website.siteID || null,
};

const actions = {
  setWebsites({
    state, commit, dispatch, rootGetters,
  }, params) {
    commit('setWebsites', { websites: params.account.sites, rootGetters });
    const currentSite = params.website ? state.websites.find(
      (website) => website.siteID === params.website.siteID,
    ) : state.websites[0];
    dispatch('setWebsite', currentSite);
  },
  setPages({ commit, rootGetters }, pages) {
    commit('setPages', { pages, rootGetters });
  },
  setSelectedPage({ commit }, selectedPage) {
    commit('setSelectedPage', selectedPage);
  },
  setPreviews({ commit, rootGetters }, previews) {
    commit('setPreviews', { previews, rootGetters });
  },
  setWebsite({ commit }, website) {
    commit('setWebsite', website);
  },
  setAllWebsites({ commit }, allWebsites) {
    commit('setAllWebsites', allWebsites);
  },
};

const mutations = {
  setWebsites(state, params) {
    const { websites, rootGetters } = params;
    const { userRestrictions } = rootGetters;

    const userWebsiteRestrictions = userRestrictions && userRestrictions.websites
      ? userRestrictions.websites.map((site) => site.siteID) : null;

    const filteredSites = userWebsiteRestrictions ? websites.filter(
      (website) => userWebsiteRestrictions.includes(website.siteID),
    ) : websites;

    state.websites = filteredSites.sort((a, b) => a.siteID.localeCompare(b.siteID));
    ls.set('websites', state.websites);
  },
  setPages(state, params) {
    const { pages, rootGetters } = params;
    const { userRestrictions } = rootGetters;

    const matchingSiteRestriction = userRestrictions && userRestrictions.websites.find(
      (site) => site.siteID === state.website.siteID,
    );

    if (!matchingSiteRestriction || !matchingSiteRestriction.pages) {
      state.pages = pages;
      return ls.set('pages', state.pages);
    }

    // Site restriction to apply
    const filteredGroupedPages = Object.keys(pages).reduce((allPages, group) => {
      const filteredGroup = pages[group].filter(
        (page) => matchingSiteRestriction.pages.includes(page.url.split('.io/').pop()),
      );

      const formattedPages = { ...allPages };
      if (filteredGroup && filteredGroup.length) {
        formattedPages[group] = filteredGroup;
      }
      return formattedPages;
    }, {});

    state.pages = filteredGroupedPages;
    return ls.set('pages', state.pages);
  },
  setSelectedPage(state, page) {
    state.selectedPage = page;
    ls.set('selectedPage', state.selectedPage);
  },
  setPreviews(state, params) {
    const { previews, rootGetters } = params;
    const { userRestrictions } = rootGetters;

    const matchingSiteRestriction = userRestrictions && userRestrictions.websites.find(
      (site) => site.siteID === state.website.siteID,
    );

    const flattenedPreviews = Object.keys(previews).reduce(
      (allPreviews, group) => [...allPreviews, ...previews[group]], [],
    );

    const filteredPreviews = matchingSiteRestriction && matchingSiteRestriction.pages
      ? flattenedPreviews.filter(
        (preview) => matchingSiteRestriction.pages.includes(preview.url.split('.io/').pop()),
      )
      : flattenedPreviews;

    state.previews = filteredPreviews;
    ls.set('previews', state.previews);
  },
  setWebsite(state, website) {
    state.website = website;
    ls.set('website', state.website);
  },
  setAllWebsites(state, allWebsites) {
    state.allWebsites = allWebsites.sort((a, b) => a.localeCompare(b));
    ls.set('allWebsites', state.allWebsites);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
