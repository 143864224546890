import http from '@/utils/http';

const state = {
  workspaceSettings: {},
  initialWorkspaceSettings: {},
  workspacesList: [],
  showWorkspaceSettings: false,
  workspaceSettingsTab: 'data',
  initialSettings: {},
  currentSettings: {},
  defaultSettings: {},
  settingsStore: [],
  settingsStoreIndex: -1,
  widgetsList: [],
  archivedWidgetsList: [],
  activeSection: null,
  editorKey: null,
  previewKey: null,
  previewSize: 'desktop',
  mobileMenuActive: false,
  showWidgetSettings: false,
  widgetSettingsTab: 'styles',
};

const getters = {
  // eslint-disable-next-line max-len
  workspaceSettingsChanged: (state) => JSON.stringify(state.initialWorkspaceSettings) !== JSON.stringify(state.workspaceSettings),
  settingsChanged: (state) => JSON.stringify(state.initialSettings) !== JSON.stringify(state.currentSettings),
  noSections: (state) => !state.currentSettings?.sections?.length,
  noShownSections: (state) => !state.currentSettings?.sections?.filter(section => section.show).length,
};

const mutations = {
  UPDATE_WORKSPACE_SETTINGS(state, params) {
    state.workspaceSettings = params;
  },
  UPDATE_INITIAL_WORKSPACE_SETTINGS(state, params) {
    state.initialWorkspaceSettings = params;
  },
  UPDATE_WORKSPACES_LIST(state, params) {
    state.workspacesList = params;
  },
  UPDATE_WORKSPACE_SETTINGS_ACTIVE(state, params) {
    state.showWorkspaceSettings = params;
  },
  UPDATE_WORKSPACE_SETTINGS_TAB(state, params) {
    state.workspaceSettingsTab = params;
  },
  UPDATE_INITIAL_SETTINGS(state, params) {
    state.initialSettings = params;
  },
  UPDATE_CURRENT_SETTINGS(state, params) {
    state.currentSettings = params;
  },
  UPDATE_DEFAULT_SETTINGS(state, { type, typeDefaultSettings }) {
    state.defaultSettings = {
      ...state.defaultSettings,
      [type]: typeDefaultSettings
    };
  },
  UPDATE_SETTINGS_STORE(state, params) {
    state.settingsStore = params;
  },
  UPDATE_SETTINGS_STORE_INDEX(state, params) {
    state.settingsStoreIndex = params;
  },
  UPDATE_EDITOR_KEY(state) {
    state.editorKey = Date.now();
  },
  UPDATE_WIDGETS_LIST(state, params) {
    state.widgetsList = params;
  },
  UPDATE_ARCHIVED_WIDGETS_LIST(state, params) {
    state.archivedWidgetsList = params;
  },
  UPDATE_ACTIVE_SECTION(state, params) {
    state.activeSection = params;
  },
  UPDATE_PREVIEW_KEY(state) {
    state.previewKey = Date.now();
  },
  UPDATE_PREVIEW_SIZE(state, params) {
    state.previewSize = params;
  },
  UPDATE_MOBILE_MENU_ACTIVE(state, params) {
    state.mobileMenuActive = params;
  },
  UPDATE_WIDGET_SETTINGS_ACTIVE(state, params) {
    state.showWidgetSettings = params;
  },
  UPDATE_WIDGET_SETTINGS_TAB(state, params) {
    state.widgetSettingsTab = params;
  },
};

const actions = {
  setWorkspaceSettings({ commit }, params) {
    commit('UPDATE_WORKSPACE_SETTINGS', { ...params });
  },
  setInitialWorkspaceSettings({ commit }, params) {
    commit('UPDATE_INITIAL_WORKSPACE_SETTINGS', { ...params });
  },
  setWorkspacesList({ commit }, params) {
    commit('UPDATE_WORKSPACES_LIST', params);
  },
  setShowWorkspaceSettings({ commit }, params) {
    commit('UPDATE_WORKSPACE_SETTINGS_ACTIVE', params);
  },
  setWorkspaceSettingsTab({ commit }, params) {
    commit('UPDATE_WORKSPACE_SETTINGS_TAB', params);
  },
  setInitialSettings({ commit }, params) {
    commit('UPDATE_INITIAL_SETTINGS', { ...params });
  },
  setCurrentSettings({ commit }, params) {
    commit('UPDATE_CURRENT_SETTINGS', { ...params });
  },
  setSettingsStore({ commit }, params) {
    commit('UPDATE_SETTINGS_STORE', params);
  },
  setSettingsStoreIndex({ commit }, params) {
    commit('UPDATE_SETTINGS_STORE_INDEX', params);
  },
  clearSettingsStore({ dispatch }) {
    dispatch('setSettingsStore', []);
    dispatch('setSettingsStoreIndex', -1);
  },
  undoSettingsStore({ state, dispatch, commit }) {
    dispatch('decrementSettingsStoreIndex');
    const { settingsStore, settingsStoreIndex } = state;
    dispatch('setCurrentSettings', settingsStore[settingsStoreIndex]);
    dispatch('updatePreview');
    commit('UPDATE_EDITOR_KEY');
  },
  redoSettingsStore({ state, dispatch, commit }) {
    dispatch('incrementSettingsStoreIndex');
    const { settingsStore, settingsStoreIndex } = state;
    dispatch('setCurrentSettings', settingsStore[settingsStoreIndex]);
    dispatch('updatePreview');
    commit('UPDATE_EDITOR_KEY');
  },
  incrementSettingsStoreIndex({ state, dispatch }) {
    dispatch('setSettingsStoreIndex', state.settingsStoreIndex + 1);
  },
  decrementSettingsStoreIndex({ state, dispatch }) {
    dispatch('setSettingsStoreIndex', state.settingsStoreIndex - 1);
  },
  setWidgetsList({ commit }, params) {
    commit('UPDATE_WIDGETS_LIST', params);
  },
  setArchivedWidgetsList({ commit }, params) {
    commit('UPDATE_ARCHIVED_WIDGETS_LIST', params);
  },
  setActiveSection({ commit }, params) {
    commit('UPDATE_ACTIVE_SECTION', params);
  },
  setPreviewSize({ commit }, params) {
    commit('UPDATE_PREVIEW_SIZE', params);
  },
  setMobileMenuActive({ commit }, params) {
    commit('UPDATE_MOBILE_MENU_ACTIVE', params);
  },
  setShowWidgetSettings({ commit }, params) {
    commit('UPDATE_WIDGET_SETTINGS_ACTIVE', params);
  },
  setWidgetSettingsTab({ commit }, params) {
    commit('UPDATE_WIDGET_SETTINGS_TAB', params);
  },
  async updatePreview({ rootState, dispatch, commit }) {
    dispatch('toggleLoader', {
      showLoader: true,
      message: 'Updating preview...',
    });
    const params = {
      accountID: rootState.accounts.account.accountID,
      workspaceID: rootState.widgets.workspaceSettings.id,
      fileType: 'preview',
      settings: rootState.widgets.currentSettings,
    };
    await http.put('widgets', params, { headers: { 'content-type': 'application/json' } });
    commit('UPDATE_PREVIEW_KEY');
    dispatch('toggleLoader', {
      showLoader: false,
    });
  },
  updateCurrentSettingsAndStore({ state, dispatch }, params) {
    dispatch('setCurrentSettings', JSON.parse(JSON.stringify({ ...params })));
    const { settingsStore, settingsStoreIndex } = state;
    const updatedStore = settingsStore;
    // remove all objs in store after current index before latest change
    if (settingsStore.length > settingsStoreIndex + 1) {
      updatedStore.length = settingsStoreIndex + 1;
    }
    updatedStore.push(JSON.parse(JSON.stringify({ ...params })));
    dispatch('setSettingsStore', updatedStore);
    dispatch('incrementSettingsStoreIndex');
  },
  async fetchDefault({ state, commit }, type) {
    const { defaultSettings } = state;

    if (defaultSettings[type]) {
      return defaultSettings[type];
    }

    let key;
    if (type === 'widget-default' || type === 'workspace-default') {
      key = type;
    } else {
      key = `components/${type}/default`;
    }
    
    const typeDefaultSettings = await http.get(`widgets/components/default?key=${key}.json`);

    commit('UPDATE_DEFAULT_SETTINGS', { type, typeDefaultSettings });

    return typeDefaultSettings;
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
