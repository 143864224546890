import axios from 'axios';
import ls from 'localstorage-ttl';
import { appConfig as config } from '../../config';

const http = axios.create({
  baseURL: config.api.domain,
  responseType: 'json',
});

// Add request interceptor
http.interceptors.request.use(
  (httpConfig) => {
    const authConfig = { ...httpConfig };
    authConfig.headers.authorization = `Bearer ${ls.get('access_token')}`;
    return authConfig;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
http.interceptors.response.use((response) => response.data,
  (error) => {
    const { response } = error;
    const message = response && response.data ? response.data.message : null;
    const statusText = response && response.statusText ? response.statusText : 'API Server Unavailable';
    const formattedError = message || statusText;
    // Do something with response error
    return Promise.reject(formattedError);
  });

export default http;
