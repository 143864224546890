<template>
  <v-app v-if="$route.name">
    <template v-if="isAuthenticated">
      <DefaultLayout />
    </template>
    <template v-else>
      <SignedOutLayout />
    </template>
    <Notifications />
    <Loading />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DefaultLayout from '@/components/views/layouts/DefaultLayout';
import SignedOutLayout from '@/components/views/layouts/SignedOutLayout';
import Notifications from '@/components/notifications/Notifications';
import Loading from '@/components/shared/Loading';

export default {
  name: 'App',
  components: {
    DefaultLayout,
    SignedOutLayout,
    Notifications,
    Loading,
  },
  computed: {
    ...mapState(['users']),
    ...mapGetters(['isAuthenticated', 'superAdmin']),
  },
};
</script>

<style lang="scss">
  @import './assets/styles/main/main';
</style>

<style>
  .v-list-group__header {
    padding: 0px;
  }
  .v-list-group__header__append-icon {
      display: none;
  }
</style>
