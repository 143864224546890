import ls from 'localstorage-ttl';
import moment from 'moment';
import http from './http';

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments; // eslint-disable-line
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function formatUnixTimestamp(timestamp, timeFormat) {
  const format = timeFormat || ls.get('dateFormat');
  let formattedTimestamp = `${timestamp}`;
  if (formattedTimestamp.length === 10) {
    formattedTimestamp += '000';
  }
  return formattedTimestamp ? moment(Number(formattedTimestamp)).format(format)
    : formattedTimestamp;
}

export function isZoneAuthorised(zonePath, store) {
  if (store.getters.superAdmin) return true;
  if (
    !store.getters.userRestrictions
    || !store.getters.userRestrictions.zones
    || !store.getters.userRestrictions.zones.length
  ) return false;
  return store.getters.userRestrictions.zones.includes(zonePath);
}

export async function uploadSSGImage(image, pageDetails, s3, usePagePath) {
  const { title, subDirectory } = pageDetails;
  const formData = new FormData();
  formData.set('image', image.file);
  formData.set('imageDetails', JSON.stringify({
    s3,
    name: image.name,
    title,
    subDirectory,
    usePagePath,
  }));

  return http.post('pages/uploadImage', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

export default {
  debounce,
  dateTime: {
    getUnixTimestamp(dateTime = new Date(), amount, unit, ms = false) {
      const formattedDateTime = parseInt(
        (new Date(dateTime).getTime() / (ms ? 1 : 1000)).toFixed(0),
        10,
      );

      if (!unit || !Number.isInteger(amount)) {
        return formattedDateTime;
      }

      const unitFunc = `get${unit.charAt(0).toUpperCase() + unit.toLowerCase().slice(1)}InSeconds`;

      if (this[unitFunc] && typeof this[unitFunc] === 'function') {
        return formattedDateTime + ((amount * this[unitFunc]()) * (ms ? 1000 : 1));
      }

      return formattedDateTime;
    },
    getHourInSeconds(ms = false) {
      return 3600 * (ms ? 1000 : 1);
    },
    getDayInSeconds(ms = false) {
      return this.getHourInSeconds() * 24 * (ms ? 1000 : 1);
    },
    getWeekInSeconds(ms = false) {
      return this.getDayInSeconds() * 7 * (ms ? 1000 : 1);
    },
    formatUnixTimestamp,
  },
  setApiAuthorizationHeader() {
    this.$http.defaults.headers.common.Authorization = `Bearer ${ls.get('access_token')}`;
  },
};
