import auth0 from 'auth0-js';

import ls from 'localstorage-ttl';
import { promisify } from 'es6-promisify';
import { appConfig as config } from '../../config';
import axios from '../utils/http';

const webAuth = new auth0.WebAuth(config.auth0);

export default {
  validateUser(user) {
    if (!user[config.auth0.namespace]) {
      throw new Error('Access Denied. You do not have the correct permissions.');
    }

    if (!user.email_verified) {
      throw new Error('Please verify your account. You should have received and email with a verification link.');
    }

    return {
      ...user,
      authenticated: true,
      name: user[config.auth0.namespace].name,
      loggedInBefore: !!user[config.auth0.namespace].loggedInBefore,
    };
  },
  async login(username, password) {
    const login = promisify(webAuth.client.login).bind(webAuth.client);
    const userInfo = promisify(webAuth.client.userInfo).bind(webAuth.client);

    const data = await login({
      username,
      password,
      realm: config.auth0.connection,
      audience: config.auth0.audience,
    });

    if (!data.accessToken) {
      return false;
    }

    // Set Auth token on local session storage
    // access token just for non-auth0 V3 API endpoints
    ls.set('access_token', data.accessToken);
    // Get user from auth0 + get userToken for making requests to auth0 user endpoints
    const [
      user,
      userToken,
    ] = await Promise.all([
      userInfo(data.accessToken),
      axios.get('auth/userToken'),
    ]);

    // Validate user
    user.expiry = data.expiresIn * 1000;
    const validatedUser = this.validateUser(user);

    if (userToken) {
      ls.set('user_token', userToken.access_token);
    }

    // Return the user
    return validatedUser;
  },
  async forgotPassword(email) {
    const changePassword = promisify(webAuth.changePassword).bind(webAuth);

    return changePassword({
      connection: config.auth0.connection,
      email,
    });
  },
};
