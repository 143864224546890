// Template version: 1.1.3
// see http://vuejs-templates.github.io/webpack for documentation.

module.exports = {
  appConfig: {
    auth0: {
      domain: 'supadu.auth0.com',
      clientID: '9oywWRECVDaiSetayfz74Jl06l4T9IWd',
      connection: 'Username-Password-Authentication',
      namespace: 'https://www.supadu.com',
      audience: 'supafolio:dashboard',
    },
    api: {
      domain: process.env.VUE_APP_API_DOMAIN,
    },
    imgix: {
      domain: 'https://supadu-io.imgix.net'
    },
    foxycart: {
      domain: 'https://admin.foxy.io'
    },
    widgets: {
      domain: process.env.VUE_APP_NODE_ENV === 'production' ? 'https://widgets.supadu.com' : 'https://widgets-staging.supadu.com'
    }
  }
};
