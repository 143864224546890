/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import ls from 'localstorage-ttl';
import http from '@/utils/http';

const state = {
  accounts: ls.get('accounts') || [],
  account: ls.get('account') || null,
  allAccounts: ls.get('allAccounts') || [],
  catalog: ls.get('catalog') || null,
  catalogs: ls.get('catalogs') || [],
  invoices: ls.get('invoices') || [],
  allCatalogs: ls.get('allCatalogs') || [],
  dateFormat: ls.get('dateFormat') || 'DD/MM/YYYY',
  quickFilters: ls.get('quickFilters') || null,
  retailerLinks: ls.get('retailerLinks') || null,
};

const getters = {
  getSplicedCatalogs: (state) => (catalogID) => state.catalogs.filter(
    (catalog) => catalog.catalogID !== catalogID,
  ),
  selectedCatalog: (state) => state.catalog,
};

const actions = {
  async setAccounts({ rootState, commit, dispatch }, params) {
    // Get all accounts
    const accounts = await http.get(`accounts?cache=${params.cache}`);

    // Only continue login if accounts were found for the user
    if (!accounts || !accounts.length) {
      throw new Error('Access Denied. You do not have permissions to any accounts.');
    }

    // Update all accounts
    const expiry = params && params.expiry ? params.expiry : rootState.users.user.expiry;
    const filteredAccounts = process.env.VUE_APP_NODE_ENV === 'production'
      ? accounts.filter((account) => account.accountID !== 'supadu')
      : accounts.filter((account) => account.accountID === 'supadu');

    commit('setAccounts', { accounts, expiry });

    const selectedWebsite = params.website || null;

    if (!params.account) {
      // Set initial account
      dispatch('setAccount', {
        account: filteredAccounts[0],
        website: selectedWebsite,
      });
    } else {
      // Update provided account
      dispatch('setAccount', {
        account: filteredAccounts.find((account) => account.accountID === params.account.accountID),
        website: selectedWebsite,
      });
    }
  },
  setAccount({ commit, state, dispatch }, params) {
    // Set Account
    commit('setAccount', params.account);

    // Set Catalogs, selected Catalog and DashboardSettings
    dispatch('setCatalogs', { catalogs: state.account.catalogs });
    dispatch('setInvoices');
    dispatch('setCatalog', { catalog: state.account.catalogs[0] });
    dispatch('setDashboardSettings', state.account.catalogs[0]);

    // Set Websites and selected Website
    dispatch('setWebsites', {
      account: state.account,
      website: params.website || (state.account.sites ? state.account.sites[0] : null),
    });
  },
  setCatalogs({ commit }, params) {
    commit('setCatalogs', params);
  },
  async setInvoices({ commit, state }, invoiceDate) {
    const invoices = await http.get(`assets/all?bucket=supadu-static-invoices&folder=${state.account.config.billing.companyID}`);
    let formattedInvoices = invoices.map(
      (pdf) => {
        const values = pdf.Key.split('---');
        const [invoiceNumber, currency, price, date] = values;
        return {
          invoiceNumber: invoiceNumber.split('/').pop(),
          total: `${currency} ${price}`,
          date: date.split('T')[0],
          pdf: pdf.SignedUrl,
          fileName: pdf.Key,
        };
      },
    ).sort((invoice1, invoice2) => new Date(invoice2.date) - new Date(invoice1.date));
    if (invoiceDate) {
      formattedInvoices = formattedInvoices.filter((item) => item.date.includes(invoiceDate));
    }
    commit('setInvoices', formattedInvoices);
  },
  setCatalog({ commit }, params) {
    commit('setCatalog', params);
  },
  setDashboardSettings({ commit }, catalog) {
    commit('setDashboardSettings', catalog);
  },
  setAllAccounts({ commit }, accounts) {
    commit('setAllAccounts', accounts);
  },
  setAllCatalogs({ commit }, catalogs) {
    commit('setAllCatalogs', catalogs);
  },
};

const mutations = {
  setAccounts(state, params) {
    state.accounts = params.accounts.sort((a, b) => a.accountID.localeCompare(b.accountID));
    ls.set('accounts', state.accounts, params.expiry);
  },
  setAccount(state, params) {
    state.account = params;
    ls.set('account', state.account);
  },
  setCatalogs(state, params) {
    state.catalogs = params.catalogs.sort((a, b) => a.catalogID.localeCompare(b.catalogID));
    ls.set('catalogs', state.catalogs);
  },
  setInvoices(state, params) {
    state.invoices = params;
    ls.set('invoices', state.invoices);
  },
  setCatalog(state, params) {
    state.catalog = params.catalog;
    ls.set('catalog', state.catalog);
  },
  setDashboardSettings(state, catalog) {
    // dateFormat
    state.dateFormat = catalog && catalog.dashboardSettings && catalog.dashboardSettings.dateFormat
      ? catalog.dashboardSettings.dateFormat : 'DD/MM/YYYY';
    ls.set('dateFormat', state.dateFormat);

    // quickFilters
    state.quickFilters = catalog && catalog.dashboardSettings && catalog.dashboardSettings.filters
      ? catalog.dashboardSettings.filters
      : { products: [] };
    ls.set('quickFilters', state.quickFilters);

    // retailerLinks
    state.retailerLinks = catalog && catalog.dashboardSettings
      && catalog.dashboardSettings.retailerLinks
      ? catalog.dashboardSettings.retailerLinks
      : null;
    ls.set('retailerLinks', state.retailerLinks);
  },
  setAllAccounts(state, accounts) {
    state.allAccounts = accounts.sort((a, b) => a.localeCompare(b));
    ls.set('allAccounts', state.allAccounts);
  },
  setAllCatalogs(state, catalogs) {
    state.allCatalogs = catalogs.sort((a, b) => a.localeCompare(b));
    ls.set('allCatalogs', state.allCatalogs);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
