const state = {
  showLoader: false,
  message: '',
};

const mutations = {
  UPDATE_LOADER(state, params) {
    state.showLoader = params.showLoader;
    state.message = params.message || '';
  },
};

const actions = {
  toggleLoader({ commit }, params) {
    commit('UPDATE_LOADER', {
      ...params,
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
