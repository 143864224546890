<template>
  <div class="notifications">
    <transition-group name="fade">
      <Notification
        v-for="notification in notifications.notifications"
        :key="notification.id"
        :notification="notification"
        transition="fade"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Notification from '@/components/notifications/Notification';

export default {
  name: 'NotificationsList',
  components: {
    Notification,
  },
  computed: {
    ...mapState(['notifications']),
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
.notifications {
    position: fixed;
    top: 32px;
    right: 32px;
    width: 450px;
    z-index: 9999;
}
</style>
