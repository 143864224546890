<template>
  <div>
    <MainSidebar v-if="notEditWidget" />
    <v-main>
      <Navbar v-if="notEditWidget" />
      <div :class="{ 'pa-4': notEditWidget }">
        <router-view />
      </div>
    </v-main>
  </div>
</template>

<script>
import Navbar from '@/components/navigation/Navbar';
import MainSidebar from '@/components/navigation/MainSidebar';

export default {
  name: 'DefaultLayout',
  components: {
    Navbar,
    MainSidebar,
  },
  computed: {
    notEditWidget() {
      return this.$route.meta.pageTitle !== 'Edit Widget';
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;700&display=swap');

:deep .v-list-group__header {
  padding: 0;
  &__append-icon {
    display: none;
  }
}

.v3-navigation__list--item {
  &.v-list-item--active {
    font-weight: 600;
  }
  &:not(&.v-list-item--active) {
      border-left: none;
  }
}

.widgets-list, .widget-edit {
  font-family: 'Nunito', sans-serif;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 6px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0));
    animation: shimmer 1s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
</style>
