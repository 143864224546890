const state = {
  miniSidebar: false,
};

const mutations = {
  setMiniSidebar(state, params) {
    state.miniSidebar = params;
  },
};

const actions = {
  dispatchMiniSidebar({ commit }, params) {
    commit('setMiniSidebar', params);
  },
};

export default {
  state,
  mutations,
  actions,
};
