<template>
  <v-alert
    class="notification"
    :type="notification.type || 'info'"
  >
    <div class="notification__content">
      <div>
        <h4
          v-if="notification.title"
          class="notification__title"
        >
          {{ notification.title }}
        </h4>
        <p class="notification__text">
          {{ notification.text }}
        </p>
      </div>
      <v-btn
        icon="cancel"
        variant="plain"
        class="notification__close"
        aria-label="Close alert"
        type="button"
        @click="removeNotification(notification)"
      />
    </div>
    <div v-if="notification.links">
      <template
        v-for="(link, i) in notification.links"
      >
        <template v-if="link.text && link.url">
          <router-link
            v-if="link.type === 'router-link'"
            :key="i"
            :to="link.url"
            class="notification__link"
            @click="removeNotification(notification)"
          >
            {{ link.text }}
          </router-link>
          <a
            v-else
            :key="`empty-${i}`"
            :href="link.url"
            :target="link.target || '_blank'"
            class="notification__link"
            @click="removeNotification(notification)"
          >
            {{ link.text }}
          </a>
        </template>
      </template>
    </div>
  </v-alert>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'NotificationItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    const timeout = this.notification.hasOwnProperty('timeout') ? this.notification.timeout : true;
    if (timeout) {
      const delay = this.notification.delay || 10000;
      setTimeout(() => {
        this.removeNotification(this.notification);
      }, delay);
    }
  },
  methods: {
    ...mapActions(['removeNotification']),
  },
};
</script>

<style lang="scss" scoped>
.notification {
  width: 100%;
  margin-bottom: 24px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 16px;
  }
  &__text {
    margin: 0;
    font-size: 14px;
  }
  &__close {
    margin: -16px -8px -16px 8px;
  }
  &__link {
    color: #fff;
    text-decoration: underline;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s all;
    &:hover {
        color: #e3e3e3;
    }
  }
}
</style>
