<template>
  <v-overlay
    :model-value="loader.showLoader"
    style="z-index:9998;"
  >
    <v-progress-circular
      indeterminate
      :size="50"
      :width="3"
      color="white"
    />
    <p
      v-if="loader.message"
      class="pt-4 text-white"
    >
      {{ loader.message }}
    </p>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingOverlay',
  computed: {
    ...mapState(['loader']),
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-overlay__content) {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
