import { createStore } from 'vuex';

// modules
import users from '@/store/modules/users';
import accounts from '@/store/modules/accounts';
import websites from '@/store/modules/websites';
import navigationDraw from '@/store/modules/navDraw';
import notifications from '@/store/modules/notifications';
import loader from '@/store/modules/loader';
import widgets from '@/store/modules/widgets';

export default createStore({
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users, accounts, websites, navigationDraw, notifications, loader, widgets,
  },
});
