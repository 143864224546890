const state = {
  notifications: [],
};

const mutations = {
  ADD_NOTIFICATION(state, params) {
    state.notifications.push(params);
  },
  REMOVE_NOTIFICATION(state, params) {
    const index = state.notifications.findIndex((notification) => notification.id === params.id);
    state.notifications.splice(index, 1);
  },
};

const actions = {
  addNotification({ commit }, params) {
    commit('ADD_NOTIFICATION', {
      id: Date.now(),
      ...params,
    });
  },
  removeNotification({ commit }, params) {
    commit('REMOVE_NOTIFICATION', params);
  },
};

export default {
  state,
  mutations,
  actions,
};
