/* eslint-disable no-param-reassign */

import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
// import filters from '@/filters'; need replacing as no longer supported
import vuetify from '@/plugins/vuetify';

import auth from '@/auth';
import http from '@/utils/http';
import userHttp from '@/utils/userHttp';
import utils from '@/utils';

import App from './App';

const app = createApp(App);

app.config.globalProperties.$auth = auth;
app.config.globalProperties.$http = http;
// used to access auth0 user endpoints
app.config.globalProperties.$userHttp = userHttp;
app.config.globalProperties.$utils = utils;

app
  // .use(filters)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');
