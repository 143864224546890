/* eslint-disable import/no-cycle */

import ls from 'localstorage-ttl';
import router from '@/router';
import { appConfig as config } from '../../../config';

const userDefault = { authenticated: false };

const state = {
  user: ls.get('user') || userDefault,
  users: ls.get('users') || [],
  zones: ['/metadata', '/sites', '/users', '/account'],
};

const getters = {
  isAuthenticated: (state) => state && state.user && state.user.authenticated,
  userRestrictions: (state) => (state && state.user
    && state.user[config.auth0.namespace] && state.user[config.auth0.namespace].access
    ? state.user[config.auth0.namespace].access.restrictions : null),
  userGroup: (state) => (state && state.user
    && state.user[config.auth0.namespace] && state.user[config.auth0.namespace].group
    ? state.user[config.auth0.namespace].group : null),
  superAdmin: (state) => state && state.user
    && state.user[config.auth0.namespace] && state.user[config.auth0.namespace].group === 'super-admin',
  admin: (state) => state && state.user
    && state.user[config.auth0.namespace] && state.user[config.auth0.namespace].group === 'admin',
  standard: (state) => state && state.user
    && state.user[config.auth0.namespace] && state.user[config.auth0.namespace].group === 'standard',
};

const actions = {
  async login({ dispatch }, params) {
    // Set user
    dispatch('setUser', { user: params.user });

    // Set Accounts
    await dispatch('setAccounts', { expiry: params.user.expiry });

    setTimeout(async () => {
      await dispatch('forceLogout');
    }, 21600000); // log out after 6 hours
  },
  logout({ dispatch }) {
    // Clear localstorage
    localStorage.clear();
    // Reset Users
    dispatch('setUser', { user: null });
    // Route login
    router.push({ name: 'Login' });
  },
  forceLogout({ dispatch }) {
    // Clear localstorage
    localStorage.clear();
    //  Reset user
    dispatch('setUser', { user: { authenticated: false, forcedLogout: true } });
    // Route login
    router.push({ name: 'Login' });
  },
  setUser({ commit }, params) {
    commit('setUser', params);
  },
  setUsers({ commit }, params) {
    commit('setUsers', params);
  },
};

const mutations = {
  setUser(state, params) {
    state.user = params.user ? params.user : userDefault;
    const expiry = params.user && params.user.expiry ? params.user.expiry : 0;
    ls.set('user', state.user, expiry);
  },
  setUsers(state, params) {
    const users = params && params.length ? params : [];
    const loggedInUser = ls.get('user');
    const activeUser = users.filter((user) => user.email === loggedInUser.email)
      .map((user) => ({ ...user, activeUser: true })).shift();
    const otherUsers = users.filter((user) => user.email !== loggedInUser.email);
    const updatedUsers = [activeUser, ...otherUsers];
    state.users = updatedUsers.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
    ls.set('users', updatedUsers);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
