/* eslint-disable import/extensions */
// Styles
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as labs from 'vuetify/labs/components';
import { aliases, md } from 'vuetify/iconsets/md';

const supaduTheme = {
  dark: false,
  // colors defined here are available as css variables with --v-theme-
  colors: {
    primary: '#4cadb3',
    secondary: '#00838F',
    accent: '#3680B3',
    success: '#72bb6c',
    info: '#4cadb3',
    error: '#ff5252',
    warning: '#FFAB00',
    white: '#FFF',
    lightgrey: '#E0E0E0',
    orange: '#b25f36',
    red: '#b23636',
    lightblack: '#464646',
    wibupaleblue: '#E9F6F6',
    wibulightblue: '#D1EAEA',
    wibublue: '#43ADBC',
    wibulightgrey: '#E9E9E9',
    wibuoffwhite: '#E6E6E6',
    wibudarkgrey: '#303030',
    wibumidgrey: '#6d7175',
    wibublack: '#000',
  },
};

export default createVuetify({
  components: {
    ...labs,
    ...components,
  },
  directives,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
  theme: {
    defaultTheme: 'supaduTheme',
    themes: {
      supaduTheme,
    },
  },
});
