<template>
  <div>
    <div class="page-title pa-8">
      <h1 class="d-flex align-center">
        <div>
          <v-icon>{{ $route.meta.icon }}</v-icon>{{ $route.meta.pageTitle }}
        </div>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
};
</script>

<style lang="scss" scoped>
.page-title {
  padding: 0 30px;
  background: #fafafa;
  top: 0;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  h1 {
    font-size: 24px;
  }
  .v-icon {
    color: rgb(var(--v-theme-primary));
    margin-right:5px;
  }
}
</style>
