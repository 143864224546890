<template>
  <v-menu location="top">
    <template #activator="{ props }">
      <v-btn
        block
        :max-width="miniSidebar ? '30' : ''"
        size="large"
        dense
        v-bind="props"
      >
        <v-avatar
          size="30px"
          :class="{ 'mr-2': !miniSidebar }"
        >
          <v-img
            :src="users.user.picture"
            :alt="users.user.name || 'User Profile'"
          />
        </v-avatar>
        <span
          v-if="!miniSidebar"
          class="text-caption"
        >{{ formattedName }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="logout">
        <v-list-item-title>Log Out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import {
  mapMutations, mapActions, mapState, mapGetters,
} from 'vuex';
import { useDisplay } from 'vuetify';

export default {
  name: 'LogoutMenu',
  setup() {
    const { smAndDown } = useDisplay();
    return { smAndDown };
  },
  computed: {
    ...mapState(['users', 'navigationDraw']),
    ...mapGetters(['superAdmin']),
    miniSidebar() {
      return this.smAndDown ? true : this.navigationDraw.miniSidebar;
    },
    formattedName() {
      const { name } = this.users.user;
      if (name.length > 15) {
        return `${name.substring(0, 15)}...`;
      }
      return name;
    },
  },
  methods: {
    ...mapMutations(['setUser', 'setCatalog', 'setCatalogs']),
    ...mapActions(['logout']),
  },
};
</script>
