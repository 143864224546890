<template>
  <v-main id="signed-out">
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: 'SignedOutLayout',
};
</script>

<style lang="scss" scoped>
#signed-out {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: bottom center !important;
  background: url('~@/assets/v3-login-background.png'), linear-gradient(to right, rgb(var(--v-theme-primary)), rgb(var(--v-theme-accent)));
}
</style>
